import { css } from "@emotion/core"
import mediaQueryGenerator from "../../utils/mediaQGen"

const [media52Dot5em, media36em] = mediaQueryGenerator([
  { type: "min", size: "52.5" },
  { type: "max", size: "36" },
])

const styles = {
  experience: css`
    color: #fff;
    h1,
    h2,
    h3 {
      color: #fff;
    }
  `,
  header: css`
    position: relative;
    padding: 0 3vmax;
    margin-top: 2rem;
    margin-left: 0rem;
    margin-bottom: 2rem;
    padding: 0.3rem 6em 0;
    text-align: center;
    h1 {
      font-size: 3rem;
      line-height: 1.25;
      margin: 0;
      ${media36em} {
        font-size: 2.2rem;
        line-height: 0.8;
      }
    }
    p {
      display: block;
      max-width: calc(100vw - 5em);
      margin: 1em 0 0 5px;
      font-weight: 500;
    }
  `,
  grid: css`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    min-height: 100vh;
    ${media36em} {
      &::before,
      &::after {
        flex-basis: auto;
      }
    }
  `,
  job: css`
    width: 50%;
    min-width: 280px;
    margin: 0 0 2em;
    padding: 1em 4em 0;
    position: relative;
    &::before {
      font-family: sans-serif;
      font-size: 10em;
      position: absolute;
      top: -1em;
      left: -0.15em;
      opacity: 0.3;
    }
    ${media52Dot5em} {
      width: 33.33%;
      margin: 0 0 9em;
    }
    ${media36em} {
      width: 100%;
      margin: 1em 0;
      padding: 1em 2em 0;
      &:nth-of-type(3n + 1) {
        order: 0;
      }
      &:nth-of-type(3n + 2) {
        order: 0;
      }
      &:nth-of-type(3n) {
        order: 0;
      }
    }
  `,
  link: css`
    display: block;
    color: #474283;
    &:hover {
      color: #474283;
      outline: none;
    }
  `,
  img: css`
    max-width: 100%;
    box-shadow: 1px 10px 10px 1px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    position: relative;
    z-index: 1;
    transform: scale(1);
    transition: 0.2s ease-in-out;
    &:hover {
      transform: scale(1.04);
    }
  `,
  year: css`
    font-size: 0.85em;
    position: absolute;
    z-index: 1;
    right: 0;
    margin: -1.4em 3em 0 0;
    padding: 0.3em 0.5em;
    color: #fff;
    border: 2px solid #fff;
  `,
  position: css`
    font-size: 1em;
    font-weight: 900;
    margin: 0 0 0 1em;
    white-space: nowrap;
  `,
  company: css`
    font-size: 0.9em;
    font-weight: 600;
    margin: 1em 0 0.5em;
  `,
}

export default styles
